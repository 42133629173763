<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="currType == 1 ? '选择供应商' : '查看详情'"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    >
    <div class="flex" style="justify-content: space-around;">
      <div class="flex" style="width:50%;">
        <el-image
          v-if="!!info.image"
          style="width: 80px; height: 80px;margin-right: 10px;"
          :src="info.image"
          fit="cover"
          :preview-src-list="[info.image]"></el-image>
        <el-image
          v-else
          style="width: 80px; height: 80px;margin-right: 10px;"
          :src="require('@/assets/img/logo-icon.jpeg')"
          fit="cover"
          :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
        <div>
          <div>{{info.norm_name}}</div>
          <div class="mt20">单位：{{info.unit_name}}</div>
        </div>
      </div>
      <div style="width:50%;">
        <div>分类：{{info.cate_name}}<span v-if="!!info.cate_tow_name"> / {{info.cate_tow_name}}</span> </div>
        <div class="mt20">截止日期：{{info.end_time}} </div>
      </div>
    </div>
    <div class="mt20">询价标准：{{info.demand}}</div>
    <div>
      <div class="mt20" style="text-align:center;font-weight: bold;">报价信息</div>
      <div class="mt20" style="display: flex;justify-content: space-between;" v-if="!!info.create_time && !!info.complete_time">
        <span>创建时间：{{info.create_time}}</span>
        <span>完成时间：{{info.complete_time}}</span>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      class="mt20"
      border
      :show-header="false"
      @selection-change="handleSelectionChange"
      >
      <el-table-column
        v-if="currType == 1"
        type="selection"
        :selectable="selectEnable"
        width="55">
      </el-table-column>
      <el-table-column
        prop="supplier_name"
        label="">
      </el-table-column>
      <el-table-column
        prop="amount"
        label=""
        width="130">
        <template slot-scope="{row}">
          <span :class="minNum == row.amount ? 'greenColor' : ''" v-if="!!row.amount">¥ {{row.amount}}</span>
          <!-- //是否选择报价 1:是 0:否 -->
          <i v-if="row.is_select == 1" class="el-icon-check" style="color:#3fa639;font-weight: bold;margin-left:10px;"></i>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label=""
        width="160">
      </el-table-column>
    </el-table>

    <template #footer v-if="currType == 1">
      <el-row type="flex" justify="center">
        <el-button type="primary" :loading="loading" :disabled="loading" @click='confirm'>确定选择</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'EnquiryDetail',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        currType: 1,
        multipleSelection: [],
        info: {},
        tableData: [],
        minNum: '',
      }
    },
    methods: {
      getDetail(row,type) {
        this.currType = type; // 1选择供应商，2详情
        if(!!row.id) {
          this.isChange = true;
          common.deepClone(row)
          this.$http.get('/admin/offer/info',{params: {id: row.id}}).then(res => {
            if(res.code == 1) {
              this.info = res.data.info;
              this.tableData = res.data.details;
              let arr = this.tableData.filter(v => v.amount > 0 )
              if(arr.length > 1) {
                this.minNum = Math.min(...arr.map(item => item.amount))
              }
            }
          })
        }
      },
      reset(done) {
        this.isChange = false;
        done && done()
      },
      toggle(show) {
        this.visible = show;
        !show && this.reset()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      confirm() {
        if(this.multipleSelection.length == 0) {
          return this.$message.warning('请选择供应商');
        } else {
          let _params =  {
            id: this.info.id,
            supplier_ids: this.multipleSelection.map(item => item.supplier_id).join(',')
          }
          this.loading = true;
          this.$http.post('/admin/offer/select', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功');
              this.toggle(false);
              this.$emit('refresh')
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      },
      selectEnable(row) {
        // amount: 报价金额(没有报价的供应商不可以选择)
        if(row.amount > 0 ) {
          return true
        }
      },
    }
  }
</script>
<style scoped>
.flex {
  display: flex;
  align-items: center;
}
.mt20 {
  margin-top: 20px;
}
</style>
