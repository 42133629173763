<template>
  <!-- 新增 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          发起询价
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">商品清单</div>

        <el-button type="primary" class="mb20 mt20" plain size="small" @click="batchAdd">批量添加</el-button>
        <el-button type="primary" class="mb20 mt20" plain size="small" @click="batchImport">批量选择供应商</el-button>
       
        <el-table
          :data="tableData"
          :header-cell-style="{background:'#f5f5f5'}"
          style="width: 100%"
          border
          ref="singleTable"
          highlight-current-row
          tabindex="-1"
          @selection-change="handleSelectionChange"
          >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            width="80">
            <template slot-scope="{}">
              <i class="el-icon-menu"></i>
            </template>
          </el-table-column>
          <el-table-column
            width="80">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
              <el-button plain style="display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品图片"
            width="80px">
            <template slot-scope="{row}">
              <el-image
                v-if="!!row.image"
                style="width: 40px; height: 40px"
                :src="row.image"
                fit="cover"
                :preview-src-list="[row.image]"></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="norm_name"
            label="*标准名称"
            width="180">
            <template slot-scope="{row,$index}">
              <el-select
                v-model="row.norm_name"
                filterable
                remote
                placeholder="请输入关键词"
                size="small"
                :remote-method="remoteMethod"
                :loading="selLoading"
                :ref="`multiSelect+${$index}`"
                @change="changeGoods($event,$index)" value-key="id">
                <el-option v-for="item in goodArr" :label="item.norm_name" :value="item" :key="item.id">
                  <div>
                    <span style="float: left">{{ item.norm_name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.unit }}</span>
                  </div>
                </el-option>
              </el-select>
              <div style="color:#ff6600;font-size:12px;" v-if="row.id == 0 && row.errText">{{row.errText}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="*单位"
            width="140">
            <template slot-scope="{row,$index}">
              <el-select
                v-model="row.unit_name"
                size="small" value-key="id" @change="changeUnit($event,$index)">
                <el-option v-for="(v,i) in unitArr" :label="v.name" :value="v.name" :key="i"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="demand"
            label="询价要求">
            <template slot-scope="{row}">
              <div style="display:flex;align-items: center;">
                <el-input
                  v-model="row.demand"
                  placeholder="请输入"
                  size="small"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="supplier_id"
            label="*选择报价供应商">
            <template slot-scope="{row}">
              <el-select v-model="row.supplier_id" multiple placeholder="多选，至少选3家" size="small">
                <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="toggle(false)">取消</el-button>
        <el-button type="primary" size="medium" @click="confirm" :loading="loading">保存</el-button>
      </div>
    </div>

    <!-- 选择供应商 -->
    <el-dialog
      title="选择供应商"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <div style="text-align:center;font-wight:bold;margin-bottom:20px;">已选商品: {{multipleSelection.length}}个</div>
        <el-form-item label="供应商" prop="supplier_id">
          <el-select v-model="form.data.supplier_id" multiple filterable style="width:100%;">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleRemark">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 提交确认弹窗 -->
    <el-dialog
      title="选择报价截止日"
      :visible.sync="dialogFormVisible"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom2'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <div style="display:flex;justify-content: space-around;margin-bottom:20px;">
          <span>询价商品：{{form2.data.goods_num}}个</span>
          <span>参与供应商：{{form2.data.supplier_num}}家</span>
        </div>
        <el-form-item label="报价截止日" prop="end_time">
          <el-date-picker
            v-model="form2.data.end_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="curPickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="form2.data.loading" @click="submitForm">确定提交</el-button>
      </div>
    </el-dialog>

    <!-- 批量选择商品弹窗 -->
    <BatchGoods ref="batchGoods" @sendgoodslist="harvestgoodslist" v-if="isShowDialog"></BatchGoods>

  </div>
</template>

<script>
  import BatchGoods from "./BatchGoods.vue"
  import { mixinTable } from "@/mixins/table.js";
  import { getStorage } from '@/storage'
  export default {
    name: 'EnquiryEdit',
    props:['sampleFile'],
    mixins: [mixinTable],
    components: {
      BatchGoods,
    },
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        multipleSelection: [],
        table:{
          params: {
            page:1,
            count: 10,
          },
          total: 0,
          data:[],
        },
        tableData: [{id:'',norm_name:'',unit_name: '',demand:'',supplier_id:[]}],
        specArr:[],
        loading: false,
        isShowDialog: false,
        goodArr: [], // 商品列表
        selLoading: false,
        unitArr: [], // 单位
        supplierArr: [], // 供应商列表
        dialogVisible: false,
        dialogFormVisible: false,
        form: {
          loading: false,
          data: {
            supplier_id: [],
          },
          rules: {
            supplier_id: [
              { required: true, message: '请选择供应商', trigger: 'change' },
            ],
          },
        },
        form2: {
          loading: false,
          data: {
            goods_num: 0,
            supplier_num:0,
            end_time: '',
          },
          rules: {
            end_time: [
              { required: true, message: '请选择截止日期', trigger: 'change' },
            ],
          },
        },
        curPickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7; // 禁用历史日期
          },
        }
      }
    },
    methods: {
      // 添加订单、采购单、盘点单，列表输入商品名称查询商品
      remoteMethod(query) {
        if(query !== '') {
          this.selLoading = true;
          this.$http.get('/admin/offer/goods', { params:{ page: 1, count: 1000, norm_name: query, }}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      getDetail(row) {
        this.getUnit(); // 获取单位
        this.getSupplier(); // 获取启用中的供应商
        this.currRow = row;
      },
      // 获取计件单位
      getUnit() {
        this.$http.get('/admin/unit/list', {params:{page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            this.unitArr = res.data.list;
          }
        })
      },
      // 获取供应商 (启用中的供应商)
      getSupplier() {
        this.$http.get('/admin/supplier/list', {params:{status:1, page:1, count:10000}}).then(res => {
          if(res.code === 1) {
            this.supplierArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false;
        this.tableData = [{id:'',name:'',supplier_id:[]}];
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          this.specArr = this.specArr.filter(j => j.id != row.id && j.unit_name != row.unit_name);
        } else {
          this.$message.warning('最后一条数据了!')
        }
      },
      // 加
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          norm_name:'',
          unit_name:'',
          demand: '',
          supplier_id:[]
        }
        this.tableData.splice(index+1,0,str); // 添加到指定位置
        this.$set(this.tableData,row,index)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = [];
      },
      // 保存
      confirm() {
        console.log(this.tableData)
        let currArr = this.tableData.filter(j => !!j.id ) // 过滤掉列表中未选商品的空的数据
        if(currArr.length == 0) {
          this.$message.warning('请先添加商品')
          return
        }
        let is_money = currArr.some((item) => item.supplier_id.length < 2); // 检测提交的数据是否至少选了2个供应商
        let is_unit = currArr.some((item) => !item.unit_name); // 检测提交的数据是否选了单位
        if(is_money) {
          this.$message.warning('每个商品请至少选择2个供应商')
          return
        }
        if(is_unit) {
          this.$message.warning('请检查数据是否选了单位')
          return
        }
        this.form2.data.goods_num = currArr.length; // 提交的数据中商品的数量
        let supplier_id_arr = currArr.reduce((acc, item) => acc.concat(item.supplier_id), [])
        this.form2.data.supplier_num = Array.from(new Set(supplier_id_arr)).length; // 提交的数据中供应商去重后的数量
        this.dialogFormVisible = true;
      },
      submitForm() {
        let currArr = this.tableData.filter(j => !!j.id ) // 过滤掉列表中未选商品的空的数据
        let _goods = currArr.map(v => {
          return {
            goods_id: v.id,
            unit_name: v.unit_name,
            demand: v.demand,
            supplier_ids: v.supplier_id.join(","),
          }
        })
        let _params = {
          end_time: this.form2.data.end_time, // //截止时间
          goods: _goods
        }
        this.form2.loading = true;
        this.$http.post('/admin/offer/add', _params).then(res => {
          if(res.code == 1) {
            this.toggle(false)
            this.$emit("refresh")
            this.$message.success('操作成功！')
            this.dialogFormVisible = false;
          }
        }).finally(() => {
          this.form2.loading = false;
        })
      },
      
      // 批量添加弹窗
      batchAdd() {
        this.isShowDialog = true
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //批量选择供应商
      batchImport() {
        if(this.multipleSelection.length > 0) {
          this.dialogVisible = true;
          this.form.data.supplier_id = [];
        } else {
          this.$message.warning("请至少选择一条数据");
          return
        }
      },
      // 接受父组件传过来的商品列表
      harvestgoodslist(val) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => {return {id:v.id, unit_name:v.unit_name}})
        this.specArr = newSpecid;
        // 验证传过来的相同商品及规格是否已经添加过
        for(const itemA of val) {
          let foundInb = false;
          for(const itemB of this.specArr){
            if(itemA.id == itemB.id && itemA.unit == itemB.unit_name) {
              foundInb = true;
              break;
            }
          }
          if(!foundInb) {
            this.tableData.push({
              ...itemA,
              unit_name:itemA.unit,
              demand: '',
              supplier_id: [],
            });
            this.specArr.push({id:itemA.id, unit_name:itemA.unit});
          }
        }
        this.isShowDialog = false;
      },
      // 选择单位
      changeUnit(value,index) {
        // 判断当前行的商品id和规格是否已经添加过
        let existsInA = this.specArr.some(item => item.id == this.tableData[index].id  && item.unit_name === value);
        if (existsInA) {
          this.$message.warning("已经添加过该相同单位的商品");
          this.tableData[index].unit_name = '';
          return
        } else {
          this.specArr.push({id:this.tableData[index].id, unit_name:value}); // 添加到规格列表
        }
      },
      // 选择商品
      changeGoods(value, index) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => {return {id:v.id, unit_name:v.unit_name}})
        this.specArr = newSpecid;

        // 判断当前行的商品id和规格是否已经添加过
        let existsInA = this.specArr.some(item => item.id == value.id  && item.unit_name === value.unit);
        if (existsInA) {
          this.$message.warning("已经添加过该相同单位的商品");
          this.tableData[index].norm_name = '';
          return
        } else {
          this.specArr.push({id:value.id, unit_name:value.unit}); // 添加到规格列表
          let _currRow = {...value,unit_name:value.unit, demand: '',supplier_id:[]};
          this.tableData[index] = _currRow;
          this.$set(this.tableData, index, {..._currRow})
        }
      },
      handleRemark() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let currIds = this.multipleSelection.map(v => v.id);
            this.dialogVisible = false;
            this.tableData = this.tableData.map(item => {
              if(currIds.indexOf(item.id) > -1) {
                console.log(currIds.indexOf(item.id),"00")
                return {
                  ...item,
                  supplier_id: this.form.data.supplier_id
                }
              }else {
                return {
                  ...item
                }
              }
            });
          }
        });
      },
      handleClose() {
        this.dialogVisible = false;
        this.dialogFormVisible = false;
      }
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>



