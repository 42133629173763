<template>
  <el-dialog
    append-to-body
    width="600px"
    top="3vh"
    title="批量添加"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    >
    <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
      <el-form-item label="下单分类" prop="order_cate_arr">
        <el-cascader
          v-model="table.params.order_cate_arr"
          filterable placeholder="请选择"
          id="order_cate_arr"
          @change="changeOrderCateId"
          @focus="getOrderCateSel"
          :options="orderCateArr"
          :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
          clearable></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetSel">重置</el-button>
      </el-form-item>
    </el-form>
    
    <el-table 
      ref="multipleTable" 
      :data="table.data" 
      border 
      v-loading="table.loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      @selection-change="handleSelectionChange" 
      :row-key="row => row.id" 
      class="mt20" 
      :max-height="500">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="norm_name" label="标准名称" ></el-table-column>
      <el-table-column prop="unit" label="单位" width="100" ></el-table-column>
      <el-table-column prop="good_img" label="图片" width="120">
        <template slot-scope="{row}">
          <el-image
            v-if="!!row.image"
            style="width: 40px; height: 40px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
          <el-image
            v-else
            style="width: 40px; height: 40px"
            :src="require('@/assets/img/logo-icon.jpeg')"
            fit="cover"
            :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageWrap" v-if="table.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.params.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.params.count"
        layout="total, sizes, prev, pager, next"
        :total="table.total">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button  size="small" @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm" size="small" >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'BatchGoodsAdd',
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: true,
        multipleSelection: [],
        table: {
          params:{
            page:1,
            count:10,
            order_cate_arr: [], //下单分类
            order_cate_id: '',
            order_cate_tow: '',
          },
          total: 0,
          data:[],
        },
        orderCateArr: [] // 下单分类
      }
    },
    created() {
      this.getTable();
    },
    methods: {
      // 获取商品列表
      getTable() {
        let _params = {
          ...this.table.params
        }
        delete _params.order_cate_arr;
        this.$http.get("/admin/offer/goods",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
          }
        })
      },
      // 重置查询条件
      resetSel() {
        this.table.params = {
          page: 1,
          count: 10,
          order_cate_arr: [], //下单分类
          order_cate_id: '',
          order_cate_tow: '',
        }
        this.getTable();
      },
      
      // 批量选择
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 提交
      confirm() {
        if(this.multipleSelection.length > 0) {
          this.$emit('sendgoodslist',this.multipleSelection)
        } else {
          this.$alert('请选择需要添加的商品', '', {
            confirmButtonText: '确定',
            type: 'error',
            callback: action => {
             
            }
          });
        }
      },
      // 取消 
      handleClose() {
        this.$parent.isShowDialog = false;
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.pageWrap {
  margin-top: 10px;
  text-align: center;
}
.cate-item {
  height: 26px;
  line-height: 26px;
  border-radius: 6px;
  padding: 0 10px;
  border: solid 1px #eee;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}
.active {
  background: #eaf7f0;
  border: solid 1px #2cb167;
  color: #2cb167;
}
</style>
